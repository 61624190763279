import "./styles.css";

import PartySocket from "partysocket";

let pingInterval;

const output = document.getElementById("app");

function add(text) {
  output.appendChild(document.createTextNode(text));
  output.appendChild(document.createElement("br"));
}

const conn = new PartySocket({
  host: PARTYKIT_HOST,
  room: "my-new-room",
});

conn.addEventListener("message", function (event) {
  add(`Received -> ${event.data}`);
});

conn.addEventListener("open", function () {
  add("Connected!");
  add("Sending a ping every 2 seconds...");

  // TODO: make this more interesting / nice
  clearInterval(pingInterval);
  pingInterval = setInterval(function () {
    conn.send("ping");
  }, 1000);
});